/* eslint-disable camelcase */
import addScript from './addScript';
import { track } from './umami';

const GA_MEASUREMENT_ID = 'G-CK9FGSNRTB';

addScript(`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`);
window.dataLayer = window.dataLayer || [];

// eslint-disable-next-line no-unused-vars
function gtag(...args) {
  // console.log('gtag', args);
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
}

gtag('js', new Date());
gtag('config', GA_MEASUREMENT_ID, {
  cookie_expires: 0,
  send_page_view: false,
});

export function pageview(title, canonical) {
  const { href } = window.location;
  const pageTitle = title.split(' / ')[0];
  const path = new URL(canonical || href).pathname;
  track((p) => ({ ...p, title: pageTitle, url: path }));
  gtag('event', 'page_view', {
    page_title: pageTitle,
    page_location: href,
    page_path: path,
    send_to: GA_MEASUREMENT_ID,
  });
}

export function tagEvent(params) {
  const { category, action, label, ...rest } = params;
  track(action, { label, ...rest });
  gtag('event', action, {
    event_category: category,
    event_label: label,
    ...rest,
    send_to: GA_MEASUREMENT_ID,
  });
}

// See https://github.com/GoogleChrome/web-vitals#send-the-results-to-google-analytics
export function sendToGoogleAnalytics({ name, delta, value, id }) {
  // Assumes the global `gtag()` function exists, see:
  // https://developers.google.com/analytics/devguides/collection/ga4
  gtag('event', name, {
    // Built-in params:
    value: delta, // Use `delta` so the value can be summed.
    // Custom params:
    metric_id: id, // Needed to aggregate events.
    metric_value: value, // Optional.
    metric_delta: delta, // Optional.

    // OPTIONAL: any additional params or debug info here.
    // See: https://web.dev/debug-performance-in-the-field/
    // metric_rating: 'good' | 'needs-improvement' | 'poor',
    // debug_info: '...',
    // ...
  });
}
