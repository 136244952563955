const scriptsLoaded = new Set();

function addScript(src, attributes = {}) {
  const allAttributes = {
    async: true,
    ...attributes,
  };
  if (scriptsLoaded.has(src)) return;
  scriptsLoaded.add(src);

  const script = document.createElement('script');
  script.src = src;
  Object.entries(allAttributes).forEach(([attr, value]) => {
    script.setAttribute(attr, value);
  });
  document.body.appendChild(script);
}

export default addScript;
