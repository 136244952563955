import addScript from './addScript';

const UNAMI_ID = window.location.hostname === 'localhost' ? 'fe61cf61-0e04-43aa-9e4b-a195b7b0d3b4' : 'dbf78f9e-e593-4b30-91a8-422384cae64b';

addScript('https://ua.harryonline.net/script.js', {
  async: true,
  'data-website-id': UNAMI_ID,
  'data-auto-track': 'false',
});

const queue = [];

// eslint-disable-next-line import/prefer-default-export
export function track(...args) {
  console.log('track', args);
  if (!window.umami) {
    queue.push(args);
    return;
  }
  while (queue.length) {
    window.umami.track(...queue.shift());
  }
  window.umami.track(...args);
}
